<template>
  <div>
    <CRow>
      <CInput
        class="col-md-12 col-12"
        size="sm"
        id="input-1"
        v-model="account.current_password"
        type="text"
        required
        placeholder="Enter your Current password"
        label="Current Password"
        maxlength="100"
      ></CInput>
    </CRow>
    <CRow>
      <CInput
        label="New Password"
        class="col-md-12 col-12"
        size="sm"
        id="input-2"
        v-model="account.new_password"
        type="text"
        required
        placeholder="Enter your New password"
        maxlength="100"
      ></CInput>
    </CRow>
    <CRow>
      <CInput
        label="Confirm Password"
        class="col-md-12 col-12"
        size="sm"
        id="input-3"
        v-model="account.confirm_password"
        type="text"
        required
        placeholder="Confirm your New password"
        maxlength="100"
      ></CInput>
    </CRow>
    <CRow class="ml-1">
      <CButton color="success" type="submit" v-on:click="onSubmitfun">
        Save</CButton
      >
    </CRow>
  </div>
</template>

<script>
export default {
  name: "AccountSetting",
  props: { account: {}, onSubmit: { type: Function } },
  methods: {
    onSubmitfun() {
      if (this.onSubmit) {
        this.onSubmit();
      }
    },
  },
};
</script>