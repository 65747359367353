<template>
  <div>
    <CRow>
      <CCol class="col-md-5 col-12">
        <CCard class="col-md-12">
          <CRow class="p-3">
            <h4 class="color-light ml-2 page_title"><span class="ul_text">AC</span>COUNT SETTING</h4>
          </CRow>
          <CCardBody>
            <account-setting :account="account" :onSubmit="onSubmitAccount"></account-setting>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-md-7 col-12">
        <CRow>
          <CCard class="col-md-12 p-3">
            <CRow>
              <CCol>
                <h4 class="color-light page_title"><span class="">Time Zone </span></h4>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol class="col-md-6 col-12">
                <CSelect required :value.sync="timezone" :options="timezone_list" />
              </CCol>
              <CCol>
                <CButton color="success" type="submit" v-on:click="updateTimeZone(timezone)">Save</CButton>
              </CCol>
            </CRow>
          </CCard>
        </CRow>
        <CRow>
          <CCard class="col-md-12 p-3">
            <CRow>
              <CCol>
                <h4 class="color-light page_title">Report Recipients :</h4>
                <CRow class="mt-3">
                  <!-- <CCol class="col-md-1 text-right"> -->
                  <span class="ml-3 f-10">Enable Hourly Report :</span>
                  <!-- </CCol> -->
                  <CCol class="col-md-1">
                    <CSwitch color="success" labelOn="ON" labelOff="OFF" :checked="recipients.hourlyReport"
                      :disabled="loading" @update:checked="enableHourlyReport()"></CSwitch>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol>
                    <span class="f-10">Enter recipient's email, press Enter to add:</span>
                    <input-tag class="col-md-12" v-model="recipients.reportRecipients" validate="email"></input-tag>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol>
                    <CButton color="success" type="submit" v-on:click="updateRecipients()" :disabled="loading">Save
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCard>
        </CRow>
      </CCol>
    </CRow>
    <CCard class="col-md-12 p-3">
      <CRow>
        <CCol>
          <h4 class="color-light page_title"><span class="">Inactivity TimeOut </span><small> (minutes)</small></h4>
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol class="col-md-3 col-12">
          <CInput size="sm" id="inactivityDuration" v-model="inactivityDuration" type="number" required
            placeholder="Enter Minutes for Logout Timeout"></CInput>
        </CCol>
        <CCol>
          <CButton color="success" type="submit" v-on:click="setLogoutTimeout" :disabled="loading">Save</CButton>
        </CCol>
      </CRow>
    </CCard>
    <CCard class="col-md-12 p-3" v-if="user == 'user'">
      <CRow>
        <CCol>
          <h4 class="color-light page_title"><span class="">SUB Value Mapping </span></h4>
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol class="col-md-1 col-12">
          <!-- <CLabel>Select Sub</CLabel> -->
          <CSelect size="sm" id="subValue" :options="subValueOpt" v-model="subValue.key" required></CSelect>
        </CCol>
        <CCol class="col-md-3 col-12">
          <!-- <CLabel>Sub Value</CLabel> -->
          <CInput size="sm" id="subValue" v-model="subValue.value" type="text" required placeholder="Enter Sub Value">
          </CInput>
        </CCol>
        <CCol>
          <CButton color="success" type="submit" v-on:click="saveSubValue()">Save</CButton>
        </CCol>
      </CRow>
      <CRow class="mt-3" v-if="showSubVal">
        <CCol>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Sub</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, key) in mappedSubValue">
                <td>{{ key }}</td>
                <td>{{ value }}</td>
                <td>
                  <CButton color="info" size="sm" @click="editSub(key, value)">Edit</CButton>
                  <CButton class="ml-2" color="danger" size="sm" @click="deleteSub(key)">Delete</CButton>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>

    </CCard>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import SettingService from "./../../services/settingControlService";
import AccountSetting from "./../../components/setting/AccountSetting";
import LoginService from './../../services/loginControlService'
import { CRow, CSelect, CSwitch } from "@coreui/vue";
import InputTag from 'vue-input-tag';
export default {
  components: { AccountSetting, InputTag },
  data() {
    return {
      loading: false,
      recipients: { hourlyReport: false, reportRecipients: [] },
      timezone: '',
      timezone_list: Intl.supportedValuesOf('timeZone'),
      user: "",
      account: { current_password: '', new_password: '', confirm_password: '' },
      inactivityDuration: 60, // 5 minutes (in milliseconds),
      subValueOpt: ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8', 'sub9', 'sub10', 'sub11', 'sub12'],
      subValue: { key: 'sub1', value: '' },
      mappedSubValue: {},
      showSubVal: true,
      filter_dash: {}
    };
  },
  mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true"
    ) {
      this.$router.push("/");
    } else {
      if (window.localStorage.getItem("user")) {
        this.user = window.localStorage.getItem("user")
      }
      // this.useremail = window.localStorage.getItem("email");
      if (window.localStorage.getItem('logoutTimeout')) {
        let logoutTimeout = window.localStorage.getItem('logoutTimeout')
        this.inactivityDuration = logoutTimeout / (1000 * 60);
        this.inactivityDuration = parseInt(this.inactivityDuration)
      }
      if (localStorage.getItem("filter_dash1")) {
        this.filter_dash = JSON.parse(localStorage.getItem("filter_dash1"));
      }
      this.getSubValue()
      // let mappedSubValue = window.localStorage.getItem('subValueMappping')
      // if(mappedSubValue){
      //   this.mappedSubValue = JSON.parse(mappedSubValue)
      // }
      if (!this.timezone_list.includes('UTC')) {
        this.timezone_list.push('UTC')
      }
    }
  },
  methods: {
    async enableHourlyReport() {
      this.loading = true
      this.recipients.hourlyReport = !this.recipients.hourlyReport
      let payload = {
        "hourlyReport": this.recipients.hourlyReport
      }
      let response = await SettingService.updateRecipients(payload)
      if (response.result) {
        Swal.fire({ title: "Success!", text: response.message, icon: "success", position: 'top-end', showConfirmButton: false, timer: 2000 });
      }
      else {
        Swal.fire({ title: "Error!", text: response.message, icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
      }
      this.loading = false
    },
    async updateRecipients() {
      this.loading = true
      let payload = {
        "reportRecipients": this.recipients.reportRecipients
      }
      let response = await SettingService.updateRecipients(payload)
      if (response.result) {
        Swal.fire({ title: "Success!", text: response.message, icon: "success", position: 'top-end', showConfirmButton: false, timer: 2000 });
      }
      else {
        Swal.fire({ title: "Error!", text: response.message, icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
      }
      this.loading = false
    },
    async updateTimeZone() {
      let response = await SettingService.updateTimeZone({ "timezone": this.timezone })
      if (response.result) {
        Swal.fire({ title: "Success!", text: response.message, icon: "success", position: 'top-end', showConfirmButton: false, timer: 2000 });
      }
      else {
        Swal.fire({ title: "Error!", text: response.message, icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
      }
    },
    async getSubValue() {
      let response = await SettingService.getSubValue()
      if (response.result == true) {
        this.mappedSubValue = response.data.subValueMappping
        this.timezone = response.data.timezone ? response.data.timezone : 'UTC'
        this.recipients.hourlyReport = response.data.hourlyReport ? response.data.hourlyReport : false
        this.recipients.reportRecipients = response.data.reportRecipients ? response.data.reportRecipients : []
      }
    },
    editSub(key, value) {
      this.subValue.key = key
      this.subValue.value = value
    },
    deleteSub(key) {
      if (confirm('Are you sure you want to delete this sub value?')) {
        let mappedSubValue = this.mappedSubValue
        delete mappedSubValue[key]
        let payload = { 'subValueMappping': mappedSubValue }
        this.subValueAPiCall(payload)
      }
    },
    saveSubValue() {
      if (this.subValue.value == '') {
        Swal.fire({ title: "Error!", text: "Please enter sub value", icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
        return false;
      }
      let mappedSubValue = this.mappedSubValue || {};
      mappedSubValue[this.subValue.key] = this.subValue.value
      let payload = { 'subValueMappping': mappedSubValue }
      this.subValueAPiCall(payload)
    },
    async subValueAPiCall(payload) {
      let response = await SettingService.saveSubValue(payload)
      if (response.result == true) {
        this.subValue.value = ''
        this.showSubVal = false
        // localStorage.setItem('subValueMappping',JSON.stringify(payload.subValueMappping))
        this.filter_dash = JSON.parse(localStorage.getItem("filter_dash1"));
        if (this.filter_dash) {
          if (this.filter_dash.projectionopt && this.filter_dash.projectionopt.length > 0) {
            this.filter_dash.projectionopt.map((item) => {
              if (item.value.startsWith('qp_')) {
                let key = item.value.substring(3);
                if (payload.subValueMappping[key]) {
                  item.text = payload.subValueMappping[key]
                }
                else {
                  item.text = key
                }
              }
            });
          }
        }
        // localStorage.setItem("filter_dash1", JSON.stringify(this.filter_dash));
        this.mappedSubValue = payload.subValueMappping
        Swal.fire({ title: "Success!", text: response.message, icon: "success", position: 'top-end', showConfirmButton: false, timer: 1000 });
        this.getSubValue();
        this.showSubVal = true
        return true;
      }
      else {
        Swal.fire({ title: "Error!", text: response.message, icon: "error", position: 'top-end', showConfirmButton: false, timer: 1000 });
        return false;
      }
    },
    setupLogoutTimer() {
      // Clear existing timeout, if any
      clearTimeout(this.logoutTimeout);
      // Set up a new timeout for logout
      this.logoutTimeout = setTimeout(() => {
        this.logout()
      }, this.inactivityDuration);
    },
    async setLogoutTimeout() {
      let payload = { "timeout": this.inactivityDuration * 60 * 1000 }
      let response = await SettingService.setLogoutTimeout(payload)
      if (response.result == true) {
        Swal.fire({ title: "Success!", text: response.message, icon: "success", position: 'top-end', showConfirmButton: false, timer: 2000 });
        window.localStorage.setItem('logoutTimeout', this.inactivityDuration * 60 * 1000)
        // this.setupLogoutTimer();
      }
      else {
        Swal.fire({ title: "Error!", text: response.message, icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
      }
    },
    async onSubmitAccount() {
      if (this.validate()) {
        let account_data = {
          oldpassword: this.account.current_password,
          newpassword: this.account.new_password,
        };
        let response = await SettingService.resetpwd(account_data);
        if (response.result == true) {
          Swal.fire({
            title: "Success!",
            text: response.message + "...Logging OUT",
            icon: "success",
            confirmButtonText: "Close", position: 'top-end', showConfirmButton: false, timer: 1000
          });
          window.setTimeout(() => {
            this.logout();
          }, 5000);
        } else {
          Swal.fire({
            title: "Error!",
            text: response.message,
            icon: "error",
            confirmButtonText: "Close", position: 'top-end', showConfirmButton: false, timer: 1000
          });
        }
        // this.errorNotify("Success", "Password Changed...Logging OUT", "success");

        // this.$router.push('Login')
      }
    },
    validate() {
      if (this.account.new_password && this.account.new_password.length < 6) {
        Swal.fire({ title: "Error!", text: "Please use minimum 6 digit password", icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
        return false;
      }
      else if (this.account.current_password == "") {
        Swal.fire({ title: "Error!", text: "Please Enter Current password.", icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
        return false;
      }
      else if (this.account.new_password == "") {
        Swal.fire({ title: "Error!", text: "Please Enter New password.", icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
        return false;
      }
      else if (this.account.confirm_password == "") {
        Swal.fire({ title: "Error!", text: "Please Enter Confirm password.", icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
        return false;
      }
      else if (this.account.new_password != this.account.confirm_password) {
        Swal.fire({ title: "Error!", text: "New password and Confirm password are not the same.", icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
        return false;
      }
      else if (this.account.current_password == this.account.new_password) {
        Swal.fire({ title: "Error!", text: "Current password and New password should be different.", icon: "error", position: 'top-end', showConfirmButton: false, timer: 2000 });
        return false;
      }
      else {
        return true;
      }
    },
    async logout() {
      let response = await LoginService.logout()
      // swal("Are you sure you want to Logout?", {
      //   buttons: ["No!", "Yes!"]
      // }).then(value => {
      //   if (value == true) {
      window.localStorage.setItem("user", null);
      window.localStorage.setItem("status", null);
      window.localStorage.setItem("email", null);
      this.$router.push("/pages/login");
      //   }
      // });
    },
  },
};
</script>

<style>
label {
  text-align: left;
  font-weight: 400;
  color: black;
  font-size: 12px;
}
</style>